import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { CLASSES, COLORS } from '../assets/styles/styles';

export default function Loader() {
  return (
    <div style={{height: 'calc(100vh - 90px)'}} className={CLASSES.content_center + ' flex-column bg-white justify-content-center align-items-center'}>
      <ThreeDots
        visible={true}
        height='15'
        width='80'
        color={COLORS.theme}
        radius='9'
        ariaLabel='loading'
        wrapperStyle={{}}
        wrapperClass={CLASSES.content_center + 'my-5'}
      />
      {/* <Watch
        visible={true}
        height="80"
        width="80"
        radius="48"
        color="#4fa94d"
        ariaLabel="watch-loading"
        // ariaLabel='three-dots-loading'
        wrapperStyle={{}}
        wrapperClass={CLASSES.content_center + 'my-5'}
      /> */}
    </div>
  )
}
