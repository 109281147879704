import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Loader from '../components/Loader';

// import Header from '../components/Header';
// import Footer from '../components/Footer';
const Header = lazy(() => import('../components/Header'));
const Footer = lazy(() => import('../components/Footer'));
const Home = lazy(() => import('../pages/home/Home'));
const About = lazy(() => import('../pages/About'));
const FAQ = lazy(() => import('../pages/FAQ'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const TermsOfUSe = lazy(() => import('../pages/TermsOfUses'));
const Privacy = lazy(() => import('../pages/Privacy'));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
const Guidelines = lazy(() => import('../pages/guidelines/Guidelines'));
const LoginGuidelines = lazy(() => import('../pages/guidelines/LoginGuidelines'));
const SecurityGuidelines = lazy(() => import('../pages/guidelines/SecurityGuidelines'));
const UploadGuidelines = lazy(() => import('../pages/guidelines/UploadGuidelines'));

class RoutersList extends Component {
  render() {
    return (
      <div style={{paddingTop: 90}} className='min-vh-100 overflow-hidden d-flex flex-column'> {/* overflow-hidden */}
        <BrowserRouter basename="/">
        <Suspense fallback={<Loader/>}>
          <Header/>
          <Suspense fallback={<Loader/>}>
            <Routes>
              <Route path="/" Component={Home} />
              <Route path="/about" Component={About} />
              <Route path="/faq" Component={FAQ} />
              <Route path="/contact" Component={ContactUs} />
              <Route path="/terms-of-uses" Component={TermsOfUSe} />
              <Route path="/guidelines" Component={Guidelines} />
              <Route path="/guidelines/login" Component={LoginGuidelines} />
              <Route path="/guidelines/security" Component={SecurityGuidelines} />
              <Route path="/guidelines/upload" Component={UploadGuidelines} />
              <Route path="/privacy" Component={Privacy} />
              <Route path="/*" Component={PageNotFound} />
            </Routes>
          </Suspense>
          <Footer/>
          </Suspense>
        </BrowserRouter>
      </div>
    );
  }
}

export default RoutersList; 